.dialog-export-content {
    padding: 0;
    width: 400px;
}


@media (max-width: 400px){
    .dialog-export-content {
        width: 290px
    } 
}