* {
}

.App {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header-page h2:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100%;
}

.HorizentalMenuDiv {
  cursor: pointer;
  padding-top: 10px;
  width: 210px;
  height: 100%;
  background: #b8b3b3e8;
  box-shadow: 0 6px 6px 2px black;
  transition: width 1s;


}

.Body {
  margin-left: 30px;
  margin-right: 10px;
  overflow-y: auto;
  height: calc(100% - 50px);
  padding-left: 0;
  position: relative;
  font-size: .8em;

}




#userProfilePic {
  border-radius: 50%;
}

.header-page {
  padding-top: 3px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  background: -webkit-linear-gradient(left, #1579fb, #1580fb);
  position: fixed;
  z-index: 1007;
}

.ContentWrapper {
  width: 100%;
  height: calc(100vh - 40px);
  display: grid;
  grid-template-columns: 210px calc(100% - 270px);
  column-gap: 10px;
  position: absolute;
  top: 51px;
  transition: all 1s ease-in-out;
}

.wip-panel{
  height: calc(100% - 50px);
  width: calc(100% - 210px);
  position: fixed;
  left: 210px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  background-color: #80808082;
  z-index: 1005;
}



@keyframes toggleMenu {
  from {
    grid-template-columns: 250px calc(100% - 270px);
  }
  to {
    grid-template-columns: 90px calc(100% - 110px);
  }
}

.header-page button.menu-toggler i {
  font-size: 1.4em;
}
.notification-section {
  display: flex;
  margin-left: auto;
}

.notification-section i.fa-bell{
  color: white;
}

.notification-section .MuiBadge-root {
  align-self: center;
  padding-right: 8px;
}

.notification-section
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorError {
  margin-top: 5px;
  margin-right: 8px;
}

.notification-section .MuiPaper-root i {
  font-size: 22px;
  padding-right: 5px;
}

.notification-section ul {
  padding-bottom: 0;
}

.notification-section li {
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
  border-top: 1px solid gray;
}

.notification-section li span {
  padding-left: 5px;
  color: red;
}

.LogedUserClass {
  align-self: center;
  margin-left: auto;
  text-align: center;
  vertical-align: middle;
  padding-right: 35px;
}

.LogedUserClass label {
  font-size: 14px;
  font-weight: 600;
}

.LogedUserClass > * {
  margin-left: 5px;
}

.LogedUserClass span {
  background: #808080e0;
  border-radius: 50%;
  border: 2px solid gray;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.LogedUserClass:hover {
  cursor: pointer;
}

.ContentWrapper.reduced {
  animation: toggleMenu 1.5s linear;
  animation-fill-mode: forwards;
  transition: all 1s ease-in-out;
}

.ContentWrapper.reduced .HorizentalMenuDiv {
  width: 90px;
  transition: width 1s;
}

.ContentWrapper.reduced .Body {
  transition: width 1s;
}

.ContentWrapper.reduced .HorizentalMenuDiv span:nth-child(2n) {
  display: none;
}


.edit-panel span.MuiCheckbox-root{
  width: 1rem !important;
  margin-top: .8rem ;
}


.no-msg-content {
  font-size: .8em;
  font-weight: 800;
  color: rgb(51, 183, 7);
  text-align: center;
}

.notification-menu-content {
  min-width: 250px;
  font-size: .8em;
}

.notification-menu-content * {
  padding: 10px;
}

.notification-menu-content .header {
  font-weight: 800;
  color: rgb(203, 13, 48);
  padding: 10px;
  border-bottom: 1px solid black;
  width: 100%;
  display: block;
}

#notification-menu ul {
  padding: 0 0 10px 0;
}

.notification-menu-content  li {
  font-size: .8em;
  color: red;
  font-weight: 700;
}

.notification-menu-content span{
  white-space: break-spaces;
}

@media (max-width: 610px) {
  button.menu-toggler {
    display: none;
  }

  .Body{
    overflow-y: auto;
    max-height: calc(100% - 135px);
  }

  .ContentWrapper {
    display: block;
  }

  .ContentWrapper .HorizentalMenuDiv {
    transition: width 1s;
    position: absolute;
    background: transparent;
    width: 50px;
    height: 275px;
    border: 0;
    box-shadow: none;

  }

  .HorizentalMenuDiv ul {
    background: #b8b3b3e8;
  }



  .horizentalMenu span:nth-child(2n) {
    display: none;
  }
  .horizentalMenu * {
    padding-left: 3px !important;
  }
  .datatable-toolbar span.MuiButton-label > span {
    display: none;
  }
  .datatable-toolbar > div:first-child {
    display: flex;
    align-items: center;
  }
  .datatable-toolbar .MuiButton-root {
    min-width: auto !important;
  }
  .Body .edit-panel div {
    display: block !important;
    padding-top: 1px;
  }

  .login > div {
    width: 85%;
  }
}

.logged-user-recap-menu {
  display: flex;
  justify-self: center;
  height: 100%;
  padding-left: 3px;
  font-size: 0.8rem;
  font-weight: bolder;
  line-height: 10px;
}

.TextAreaError {
  border-color: red;
}


td.MuiTableCell-root {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 460px) {
  .LogedUserClass label {
    display: none;
  }
  .wip-panel{
    left: 0 !important;
    top: 220px;
    width: 100%;
  }
  .Body {
    position: absolute;
    top: 120px;
    width: 100%;
  }
  .horizentalMenu >div {
    grid-template-columns: 100%;
    
  }

}

::-webkit-scrollbar {
  width: 5px;
  background: rgb(180, 180, 180);
  height: 3px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
}
