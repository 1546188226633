@import "@tomtom-international/web-sdk-maps";


#map {
  height: calc(100vh - 240px);
}

.map-view {
  position: relative;
}

.map-view > div {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: -40px;
  border: 1px solid gray;
  box-shadow: 1px 1px 1px gray;
  border-radius: 10px;
  padding: 3px;

}

.map-view button{
  padding: 0;
}

.map-view > div.active {
  border-right: 3px solid green;

}

.map-view * {
  padding: 2px;
  width: 25px;

}
.map-view i {
  color: red;
  font-size: .5em;
  border: 1px solid red;
  border-radius: 50%;
  padding: 5px;
}

.map-view label{
  font-size: .2em;
  white-space: nowrap;
  font-weight: 800;
  letter-spacing: 0px;
  word-spacing: -1px !important;
}

.map-popup .pos-details {
  display: grid;
  padding: 3px;
  grid-template-columns: repeat(2, calc(50% - 10px));
  column-gap: 5px;
}


.map-popup span{
  padding: 3px;
}

.map-popup span:nth-child(2n+1){
  font-weight: bolder;
}

.map-popup > div.controller-kpi {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
}

.map-popup > div.controller-kpi span{
  border-bottom: 1px solid gray;
  padding: 3px;
}


@media (max-width: 460px) {
  div.map-view{
    margin-left: 20px;
    position: absolute !important;
    top: 80px;
    left: 140px;
    width: 10px;
  }

  div.map-view > div{
    position: fixed;
    margin-top: 2px;
    left: 15px;
  }

  #map {
    height: calc(100vh - 315px);
  }
  
 
}