.controller .edit-panel-content{
    padding: 0 5px 5px 5px;
}
.embedded-pos-edit-panel{
    width: 80%;
    margin: auto;
    border: 1px solid rgba(0,0,0,.2);
    padding-bottom: 10px;
    margin-bottom: 10px;
    
}

.embedded-pos-edit-panel .header {
    background: aquamarine;
    padding: 10px;
    color: gray;
    text-align: center;
}

.embedded-pos-edit-panel .content{
    padding: 20px 0 20px 0;
    display: flex;
    justify-items: center;
    justify-content: center;
}

.embedded-pos-edit-panel .content input{
padding-left: 5px;
}

.newpos{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;
}




.display-panel {
    width: 90%;
    max-width: 600px;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 20px;
    margin-bottom: 20px ;
}



.display-panel > div {
    box-shadow: 1px 1px 3px gray;
    border-radius: 20px;
}

.display-panel > div > span:first-child{
    background: -webkit-linear-gradient(left, rgba(111, 111, 111, 0.9), rgba(111,111,111,.3));
    color: white;
    padding: 10px;
    display: block;
    width: calc(100% - 20px);
    letter-spacing: .2em;
    font-size: .8em;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    border-radius: 20px 20px 0 0;

}


.display-info-content {
    background-size: 150px 100px;
    background-repeat: no-repeat ;
    background-position: center;
    display: grid;
    grid-template-columns: 100px 80px;
    align-items: center;
    justify-items: center;
    column-gap: 10px;
    padding: 30px;
    row-gap: 15px;

}

.display-info-content > span{
    font-size: .6em;
}


.display-info-content > span:nth-child(2n+1){
    font-weight: 800;
    letter-spacing: .2em;
}

.command-panel .MuiIconButton-label{
  font-size: .6em;
}

.command-panel ul {
    padding: 15px;
}

.command-panel li{
    padding: 10px;
    display: flex;
    column-gap: 10px;
}

.command-panel li > span{
    padding-left: 10px 0 10px 15px;
    display: block;
    font-size: .6em;
    font-weight: 800;

}


.controller-table div.MuiTableContainer-root{
    overflow: hidden;
}


@media (max-width: 760px) {
    .controller-table div.MuiTableContainer-root{
        overflow: auto;
    }
    
}

.labels {
    width: 80%;
    margin: auto;
}

.labels .labels-content{
   overflow-y: auto;
   white-space: nowrap;
   width: inherit;
   box-sizing: border-box;
   padding: 0 10px 10px 10px;
}

.labels .MuiChip-root{
    margin-left: 5px;
    height: unset;
    font-weight: 600;
    letter-spacing: 1px;
}

.labels .inputs{
    display: flex;
    column-gap: 10px;
    flex-direction: row;
    align-items: center;
    padding: 10px;
}

.labels span.header {
    letter-spacing: 1.5px;
    font-weight: 800;
}

.labels button{
    color: #1579fb;
    padding: 2px;
}

.labels .MuiChip-label {
    padding: 2px 10px 2px 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important
}

