@import url(https://fonts.googleapis.com/css2?family=Poppins&family=Ubuntu:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
.filter-section{
  margin-top: 25px;
  line-height: 0;

}
.filter-section span:first-child {
  text-transform: uppercase;
}
.filter-section div> div:first-child{
  margin-left: 0 !important;
}

.filter-section > div{
  max-width: 90%;
  display: inline-flex;
  background: transparent !important;
  flex-direction: row;
  margin-left: 0 ! important;
  margin-top: 5px ! important;
  padding: 6px !important;
  padding-left: 0 !important;
  overflow-x: auto;

}

.filter-section>span {
  font-weight: 600;
  padding-right: 5px;
}

.filter-section >div>div * {
  display: inline;
}

.filter-section div {
  background: #8080806b;
  color: black;
  border-radius: 5px;
  padding: 4px;
  font-weight: 400;
  margin-left: 5px;
}

.filter-section div i:hover {
  cursor: pointer;
  opacity: 1.5;
}

.filter-section div i {
  margin-left: 5px;
  opacity: 0.5;
}

.filter-section div>span:first-child {
  font-size: 12px;
  font-weight: bolder;
  margin-left: 3px;
}

.filter-section div>span:nth-child(2n) {
  color: white;
  font-size: 14px;
  font-weight: bolder;
  margin-left: 3px;
}

.datatable-toolbar {
  margin-top: 25px;
  border-bottom: 1px solid black;
  display: flex;
  background: #ffffff78;
}



.red-color {
  color: red !important
}

.search-panel-content {
  border: 1px solid gray;
  background: white;
}

.search-panel-content h3 {
  margin: 0;
  padding: 10px;
  background: gray;
  color: white;
}

.search-panel-header .close-icons:hover{
  cursor: pointer;
  background: rgb(138,138,128);
}

.search-panel-content span.close-icon {
  border: 1px solid gray;
  padding: 3px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -1em;
  z-index: 1008;
  background: white;
}

.search-panel-content span.close-icon i {
  position: fixed;
  background: white;
}

.search-panel-content i.close-icon:hover {
  cursor: pointer;
}

.search-panel-content > div div {
  display: flex;
  justify-content: center;
}

.search-panel-content div > * {
  padding: 5px;
}

.search-panel-content div > label {
  align-self: center;
  font-weight: 600;
  text-transform: capitalize;
}

.search-panel-content div.action-panel {
  padding-top: 10px;
  padding-bottom: 5px;
  width: 80%;
  margin: auto;
  display: block;
  margin-top: 15px;
  text-align: center;
}

.search-panel-content div.action-panel button:first-child {
  background: green;
  color: white !important;
  margin: 5px;
}

.search-panel-content div.action-panel button:last-child {
  background: red;
  color: white !important;
  margin: 5px;
}

.search-panel-content div.action-panel button > span {
  width: 24px;
  height: 24px;
}

.search-panel-header {
  display: flex;
  flex-direction: row;
}

.search-panel-header h3 {
  flex-basis: 100%;
  background: gray;
  padding: 5px;
  color: white;
  padding-left: 15px;
}

.search-panel-header > div {
  display: inline-block;
  background: gray;
  width: 60px;
}

.search-panel-header > div > div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
}

.search-panel-header > div > div:first-child {
  background: green;
}

.search-panel-header > div > div:nth-child(2) {
  background: orange;
}

.search-panel-header > div > div:last-child {
  background: red;
}


.datatable-toolbar div.search-panel {
  position: fixed;
  min-width: 300px;
  width: 30vw;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  transition: all 0.3s linear;
  box-shadow: 1px 2px 8px;
  top: 180px

}


@media (max-width: 600px) {
  .datatable-toolbar div.search-panel {
    height: 100vh;
    width: 100vw;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background: #808080a8;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1008;
  }

  .datatable-toolbar div.search-panel > div {
    height: auto;
  }

  .search-panel-header > div {
    width: 60px;
  }
}

th {
    font-size: .8em !important;
    font-weight: 700 !important;
    white-space: nowrap;
}
td {
    font-size: .8em !important;
}

.command-table div.MuiTableContainer-root{
    overflow: hidden;
}


@media (max-width: 760px) {
    .command-table div.MuiTableContainer-root{
        overflow: auto;
    }
    
}
.controller .edit-panel-content{
    padding: 0 5px 5px 5px;
}
.embedded-pos-edit-panel{
    width: 80%;
    margin: auto;
    border: 1px solid rgba(0,0,0,.2);
    padding-bottom: 10px;
    margin-bottom: 10px;
    
}

.embedded-pos-edit-panel .header {
    background: aquamarine;
    padding: 10px;
    color: gray;
    text-align: center;
}

.embedded-pos-edit-panel .content{
    padding: 20px 0 20px 0;
    display: flex;
    justify-items: center;
    justify-content: center;
}

.embedded-pos-edit-panel .content input{
padding-left: 5px;
}

.newpos{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;
}




.display-panel {
    width: 90%;
    max-width: 600px;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    margin-bottom: 20px ;
}



.display-panel > div {
    box-shadow: 1px 1px 3px gray;
    border-radius: 20px;
}

.display-panel > div > span:first-child{
    background: -webkit-linear-gradient(left, rgba(111, 111, 111, 0.9), rgba(111,111,111,.3));
    color: white;
    padding: 10px;
    display: block;
    width: calc(100% - 20px);
    letter-spacing: .2em;
    font-size: .8em;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    border-radius: 20px 20px 0 0;

}


.display-info-content {
    background-size: 150px 100px;
    background-repeat: no-repeat ;
    background-position: center;
    display: grid;
    grid-template-columns: 100px 80px;
    align-items: center;
    justify-items: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    padding: 30px;
    grid-row-gap: 15px;
    row-gap: 15px;

}

.display-info-content > span{
    font-size: .6em;
}


.display-info-content > span:nth-child(2n+1){
    font-weight: 800;
    letter-spacing: .2em;
}

.command-panel .MuiIconButton-label{
  font-size: .6em;
}

.command-panel ul {
    padding: 15px;
}

.command-panel li{
    padding: 10px;
    display: flex;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.command-panel li > span{
    padding-left: 10px 0 10px 15px;
    display: block;
    font-size: .6em;
    font-weight: 800;

}


.controller-table div.MuiTableContainer-root{
    overflow: hidden;
}


@media (max-width: 760px) {
    .controller-table div.MuiTableContainer-root{
        overflow: auto;
    }
    
}

.labels {
    width: 80%;
    margin: auto;
}

.labels .labels-content{
   overflow-y: auto;
   white-space: nowrap;
   width: inherit;
   box-sizing: border-box;
   padding: 0 10px 10px 10px;
}

.labels .MuiChip-root{
    margin-left: 5px;
    height: unset;
    font-weight: 600;
    letter-spacing: 1px;
}

.labels .inputs{
    display: flex;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    flex-direction: row;
    align-items: center;
    padding: 10px;
}

.labels span.header {
    letter-spacing: 1.5px;
    font-weight: 800;
}

.labels button{
    color: #1579fb;
    padding: 2px;
}

.labels .MuiChip-label {
    padding: 2px 10px 2px 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important
}


.dialog-export-content {
    padding: 0;
    width: 400px;
}


@media (max-width: 400px){
    .dialog-export-content {
        width: 290px
    } 
}
.upload-content {
  margin-top: 25px;
  padding: 15px;
  text-align: center;
}

.upload-content div {
}

.log-panel-header {
  display: flex;
}

.log-panel-header span {
  font-weight: bolder;
  margin-top: -1rem;
  padding: 4px;
}

.log-panel-header i {
  font-weight: bolder;
  margin-top: -0.7rem;
  padding: 4px;
  cursor: pointer;
}

.log-panel-header i:hover {
  opacity: 0.5;
}

.log-panel-header::before {
  content: "";
  width: 20%;
  height: 1px;
  background: gray
}

.panel-header {
  background: -webkit-linear-gradient(left, #1579fb, #1580fb);
  color: white;
  padding: 10px;
  font-weight: 600;
}

.log-panel-header::after {
  content: "";
  width: 80%;
  height: 1px;
  background: gray;
}

.log-panel-content {
  font-size: 12px;
}

.log-panel-content span {
  font-weight: bolder;
  padding: 10px;
  text-transform: uppercase;
  flex-basis: 15%;
}

.log-panel-content div {
  margin-top: 10px;
  padding-left: 10px;
  display: flex;
  margin-bottom: 15px;
}
.log-panel-content div > *:nth-child(2) {
  flex-basis: 70%;
}

.log-panel-content .response-panel textarea {
  flex-basis: 100%;
  overflow-y: auto;
}



.select-file-panel  span {
  margin-top: 1rem;
  text-transform:uppercase ; 
  font-size: small;
  font-weight: 600;
  color: green
}

.select-file-panel:hover{
  cursor: pointer;
  opacity: 1;
  background: rgb(250,235,215, .03);

}

.select-file-panel:active{
  opacity: 0.5;
  background: rgb(250,235,215, .03);
}

.upload-step-panel,.select-file-panel {
  display: flex;
  height: 150px;
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
  margin-bottom: 10px;

  background: rgb(250,235,215, .33);
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px dashed green;
  box-shadow: 3px 2px 9px 0 grey;
}

.select-file-panel span {
  padding: 5px
}

#map {
  height: calc(100vh - 240px);
}

.map-view {
  position: relative;
}

.map-view > div {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: -40px;
  border: 1px solid gray;
  box-shadow: 1px 1px 1px gray;
  border-radius: 10px;
  padding: 3px;

}

.map-view button{
  padding: 0;
}

.map-view > div.active {
  border-right: 3px solid green;

}

.map-view * {
  padding: 2px;
  width: 25px;

}
.map-view i {
  color: red;
  font-size: .5em;
  border: 1px solid red;
  border-radius: 50%;
  padding: 5px;
}

.map-view label{
  font-size: .2em;
  white-space: nowrap;
  font-weight: 800;
  letter-spacing: 0px;
  word-spacing: -1px !important;
}

.map-popup .pos-details {
  display: grid;
  padding: 3px;
  grid-template-columns: repeat(2, calc(50% - 10px));
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}


.map-popup span{
  padding: 3px;
}

.map-popup span:nth-child(2n+1){
  font-weight: bolder;
}

.map-popup > div.controller-kpi {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
}

.map-popup > div.controller-kpi span{
  border-bottom: 1px solid gray;
  padding: 3px;
}


@media (max-width: 460px) {
  div.map-view{
    margin-left: 20px;
    position: absolute !important;
    top: 80px;
    left: 140px;
    width: 10px;
  }

  div.map-view > div{
    position: fixed;
    margin-top: 2px;
    left: 15px;
  }

  #map {
    height: calc(100vh - 315px);
  }
  
 
}
.users .MuiListItem-root.MuiListItem-gutters {
  padding-bottom: 0;
  padding-top: 0;
}

.users .upload-component {
  display: flex;
}

.users .upload-panel button {
  padding: 10px;
  border-radius: 50%;
  height: 40px;
}

.users .pic-displayer-panel {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.users .pic-displayer-panel img {
  border-radius: 50%;
}

.users .pic-displayer-panel input {
  width: 300px !important;
  font-weight: 800;
  font-size: 0.6em;
  text-align: center;
}

.users .fa-image {
  color: blue;
  font-size: 0.7em;
}

.users .fa-upload {
  color: green;
  font-size: 0.7em;
}

#pic-displayer {
  font-size: 0.7em !important;
  white-space: nowrap;
}

.users .contained,
.users .roles-container {
  max-width: 500px;
  margin: auto !important;
  display: flex;
  justify-items: center;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  min-height: 150px;
}

.users .contained > label {
  margin: 13px 0 0 13px;
  font-weight: 800;
  font-size: 0.9em;
  color: black;
}

.users .roles-container > ul {
  margin-top: 10px;
  font-size: 0.7em;
  font-weight: 700;
}

.pos-check-list {
  padding: 15px 0 0 10px;
}

.error-modal {
  background: white ; 
  width: 400px ;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%)

}

.error-modal > div {
  padding: 30px;
}

.error-modal > div i{
  color: orange
}

.error-modal h3{
  background : red;
  color: white;
  padding: 10px;
  font-weight: 700;
}

.error-modal span {
  font-weight: 800;
  font-size: .8em;
  margin-left: 5px;
}

.pos-check-list-content {
  overflow: auto;
  max-height: 62px;
}

.pos-check-list-content ul {
  max-height: 200px;
}



.pos-check-list-header > div {
  display: flex;
  align-items: center;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
  padding-bottom: 10px;
  justify-content: center;
}

.pos-check-list-header span {
  font-weight: 800;
  font-size: 0.7em;
}

.pos-check-list-content ul {
  list-style: none;
  padding-left: 10px;
}

.pos-check-list ul li {
  vertical-align: middle;
  padding-left: 4px;
}

.pos-check-list ul li span.MuiCheckbox-root {
  padding: 2px 3px 2px 3px;
  margin: 0;
}

.pos-check-listItem {
  padding-top: 10px;
  display: flex;
  align-items: center;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
}

.pos-check-listItem span {
  font-size: 0.7em;
  font-weight: 700;
}

#user-rel {
  width: 600px;
  margin: auto;
}

#user-rel > .pos-check-list {
  width: 500px;
}

#user-rel > .pos-check-list  input{
  width: 250px !important;
}

.users div[role="tabpanel"] {
  padding: 0;
}

@media (max-width: 990px) {
  #user-rel {
    grid-template-columns: 50% 50% !important;
  }
}

@media (max-width: 755px) {
  .Body .edit-panel .upload-component {
    display: flex !important;
    flex-direction: column;
    justify-items: center;
  }
  .pic-displayer-panel {
    margin: auto;
    text-align: center;
  }

  #user-rel {
    width: 100%
  }

  .users .pic-displayer-panel input {
    width: 100% !important;
    text-align: center;
  }

  #user-rel > .pos-check-list {
    width: 100%;
}

  #user-rel {
    grid-template-columns: 100% !important;
  }

  .ContentWrapper {
    height: calc(100% - 70px) !important;
  }
}

.dashboard-chart {
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  width: 90%;
  margin: auto;
}

.dashboard-chart > div span{
  font-weight: 800;
  letter-spacing: .2em;
  font-size: .7em;
  text-align: center;
  border-bottom: 1px solid rgb(76, 75, 75);
  padding-bottom: 5px;
}

.dashboard-chart > div{
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  width: 200px;
  border: 1px solid rgb(239, 239, 239);
  padding: 10px;
  margin: 10px;
  border-radius: 30px;
  box-shadow: 1px 1px 1px gray;

}
.card {
  padding: 10px;
  margin: 15px;
}
.cards{
  display: flex;
  flex-direction: row
}



@media only screen and (max-width: 900px) {
  .cards{
    flex-direction: column;
  }
}
.home-component {
    width: calc(90% - 20px);
    margin: auto;
    padding: 20px;
}

.home-component h2 {
    margin-top: 10px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, .7);
    text-align: center;
    font-family: monospace;
    font-size: 1.2em;
    font-weight: bolder;
    letter-spacing: .3em;
}

.home-component h3 {
    margin-top: 45px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.3em;
}

.home-link-section {
    margin-top: 30px;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 250px);
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    font-weight: 600;
    letter-spacing: .2em;
    justify-content: center;
}

.home-link-section img {
    width: 3em;
    font-weight: 800;
}

.home-link-section div {
    padding: 10px;
    box-shadow: 1px 1px 5px gray;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    grid-row-gap: 20px;
    row-gap: 20px;
    padding-bottom: 20px;
    cursor: pointer;
    transition: all ease 1s;
    background: rgba(255, 255, 255, .75) ;
}

.home-link-section div:hover {
background: rgba(0, 0, 0, .15);
}

@media (max-width: 850px) {
    .home-link-section {
        grid-template-columns: 100%;
        grid-row-gap: 20px;
        row-gap: 20px
    }
}
.publicite-workspace {
  width: calc(100% - 40px);
  padding: 20px;
}

.publicite-workspace > div > span {
  font-size: 0.8em;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.publicite-workspace .file-upload {
  display: grid;
  grid-template-columns: 400px 200px 200px;
  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  justify-content: center;
}

.publicite-workspace .upload-histroy {
  background-color: rgba(128, 128, 128, 0.294);
  padding: 20px;
  box-shadow: 1px 1px 3px gray;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 200px 200px 200px 200px;
  justify-content: center;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.publicite-workspace .upload-histroy span:nth-child(2n + 1) {
  -webkit-text-decoration: underline dashed;
          text-decoration: underline dashed;
  text-underline-offset: 8px;
}

.publicite-workspace .upload-histroy span:nth-child(2n) {
  text-transform: unset;
  color: green;
}

.publicite-workspace .workspace-compiler {
  margin: auto;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  margin-top: 20px;
}
.publicite-workspace .workspace-compiler > div {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.publicite-workspace .workspace-compiler .wrap {
  width: 100%;
  height: calc(100vh - 290px);
  padding: 0;
  overflow: hidden;
}

.publicite-workspace .workspace-compiler iframe {
  width: 1280px;
  height: 786px;
  -webkit-transform: scale(0.35);
          transform: scale(0.35);
  overflow: auto;
}

.deploy-pub-alert {
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-self: center;
  border: unset;
}
.deploy-pub-alert .pub-content {
  background: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: unset;

}

.deploy-pub-alert .message {
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  justify-items: center;
  align-items: center;
}

.deploy-pub-alert .message i {
  color: orange;
}
.deploy-pub-alert .message span {
  font-weight: 700;
}

.deploy-pub-alert .pub-toolbar button {
  margin: 20px 0 5px 10px;
}

.file-upload .MuiSelect-root{
  padding-left: 10px;
}

@media (max-width: 1028px) {
  .publicite-workspace .upload-histroy {
    grid-template-columns: 200px 200px;
    grid-row-gap: 30px;
    row-gap: 30px;
    justify-content: center;
    justify-items: center;
  }

  .publicite-workspace .file-upload {
    grid-template-columns: 300px;
    justify-content: center;
    justify-items: center;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .publicite-workspace .file-upload > div {
    display: flex;
    flex-direction: column;
    justify-items: center;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
}

@media (max-width: 760px) {
  .publicite-workspace .upload-histroy {
    grid-template-columns: calc(100% - 20px);
    grid-row-gap: 30px;
    row-gap: 30px;
    justify-content: center;
    justify-items: center;
  }

  .file-upload .MuiSelect-root{
    width: 100px;
    padding-left: 10px;
  }
}

.horizentalMenu {
  padding: 0;
  color: rgba(0, 0, 0, 0.58);

}

.horizentalMenu span, .horizentalMenu i{
  font-size: .8em;
}


.horizentalMenu li{
  padding: 5px
}


.horizentalMenu ul.level1 {
  padding: 0;
  border-top: 1px solid rgba(128, 128, 128, 0.329);
}

.horizentalMenu ul.level2 {
margin-top: 10px;
background-color: rgba(128, 128, 128, 0.129);
padding-left: 0;
margin-left: 20px
;

}
.dashboard{
  cursor: pointer;
  margin-top: 3px;
}
.dashboard:hover{
  background-color: rgba(128, 128, 128, 0.129);
}

.horizentalMenu ul.level2 li {

  border-bottom: 1px solid white;
}

.horizentalMenu ul.level2 li {
 font-size: .6em;
 padding-left: 5px;
}


.horizentalMenu span:nth-child(2n+1) {
  padding: 0 5px 0 0;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
}

.horizentalMenu i {
  font-size: 1.3em;
}

.horizentalMenu > div {
  width: 100%;
  font-size: 1em;
  padding-bottom: 5px;
  font-weight: 800;
  position: relative;
  border-bottom: 1px white solid;
  box-shadow: 1px 1px 1px gray;
}

.horizentalMenu > div > div{
  padding: 5px;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}


.horizentalMenu div ul {
  margin-left: 10px;
  margin-top: 20px;
  padding-top: 10px;
}

.horizentalMenu div li{
  padding: 8px ;
  margin-right: 10px;
  font-size: .8em;
}


.horizentalMenu ul.level1 {
  overflow: hidden;
  height: 0;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}

.horizentalMenu ul.show-menu {
  height: auto;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  transition: .7s ease;
}

div.arrow-left{
  position: relative;
}
.horizentalMenu div.arrow-left::after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 6px solid transparent;
  position: absolute;
  border-left: 6px solid white;
  border-top: 6px solid transparent;
  right: 10px;
  top: 15px;
}



.horizentalMenu div.arrow-bottom::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  position: absolute;
  border-top: 6px solid white;
  border-right: 6px solid transparent;
  right: 10px;
  top: 15px;
}

ul.horizentalMenu li {
  list-style-type: none;
  padding: 8px;
  padding-left: 20px;
  font-weight: 500;
}

ul.horizentalMenu>li {
  border-bottom: 1px solid white;
  padding: 0 10px 0 15px;
  box-shadow: 0 4px 4px grey;
}

ul.horizentalMenu>li:nth-child(n + 2) {
  padding-top: 10px;
}

.horizentalMenu li.menu:hover , .horizentalMenu li.menu.active {
  background-color: unset !important;
  color: unset;
  opacity: 1 !important
}

.horizentalMenu li.menu{
  position: relative;
}

.horizentalMenu ul>li:hover {
  cursor: pointer;
  background-color: gray;
  opacity: 0.5;
  color: white;
}

.horizentalMenu .active {
  background-color: gray;
  color: white
}


.HorizentalMenuDiv  {
  background: #b8b3b3e8 !important;
}


.horizentalMenu >div {
  padding-top: 5px;
}


@media (max-width: 460px) {

  .HorizentalMenuDiv  {
    background: none !important;
  }
  .horizentalMenu >div {
    width: 100% !important;
    display: grid !important;
    grid-template-columns: 50% 50%;
  }


  .horizentalMenu div ul {
    margin-top: 15px !important;
  }

  .horizentalMenu .level2 >li {
    margin-left: 10px;
    display: flex;
    justify-content: flex-start;
  }
  
  .horizentalMenu>li {
    padding: 0;
  }

  .ContentWrapper {
    display: grid !important;
    grid-template-columns: 100% !important;
    
  }

  .ContentWrapper .HorizentalMenuDiv {
    width: 100% !important;
    height: auto !important;
    position: fixed !important;
    z-index: 1;
  }

  .horizentalMenu {
    margin-top: 0;
    width: 100% !important;
  }


  .horizentalMenu > div{
    display: inline-flex;
    width: calc(50% - 5px);
    flex-direction: column;
  }
  .horizentalMenu > div > div{
    background: #b8b3b3e8;
  }



  .horizentalMenu > div span{
    display: inline !important;
    font-size: .8em;
  }

  .horizentalMenu > div .level1 span{
    font-size: .7em;
  }

  .Body {
    margin : 40px 0 0 0px !important;
    height: calc(100% - 80px);
  }

  .Body  span , .Body  label , .Body  td{
    font-size: .8em !important;
  }

}

.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #80808078;
}

.login > div {
  border: 1px solid gray;
  width: 500px;
}

.login div.header {
  display: inline-block;
  vertical-align: middle;
  background: -webkit-linear-gradient(left, #00eaff, #676767) !important;
  color: white;
  font-weight: bolder;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  text-align: center;
  width: 100%;
}
.content {
  width: 100%;
  background: #ffffffb8;
  padding: 15px;
}


.content > div {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;

}

.content > div input {
  background: white;
  padding-left: 20px;
  margin:0; 
  width: 100%;
}

.content >div div:nth-child(2){
  flex-basis: 100%
}


.content >div>span {

  width: 50px;
  display: flex;
  background: gray;
  justify-content: center;
}


.content i {
  display: flex;
  flex-direction: row;
  justify-content: center ;
  margin:auto;
  color: white;
  font-size: 1.8em;
  flex-basis: 50px;
}
.content input {
  border: 0;
  margin-left : 10px;
  padding-left: 10px;
  width:230px
}

.content button {
  padding: 10px;
  width: 80%;
  margin: 15px;
  font-weight: bolder;
}

span.fail {
  background: red;
  color: white;
  font-weight: bolder;
  text-align: center;
  width: 100%;
  display: inline-block;
}

span.success {
  background: rgb(0, 255, 13);
  color: white;
  font-weight: bolder;
  text-align: center;
  width: 100%;
  display: inline-block;
}

.authentication-panel {
  display: flex;
}

.authentication-panel > div:first-child {
  border: gray 1px solid;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  background: -webkit-linear-gradient(bottom, #1579fb, #1580fb) !important;
}

.authentication-panel > div:first-child > img {
  margin: auto;
}

.Navigator {
  padding: 10px;
  font-weight: bold;
  margin: 10px 5% 0 10px;
  border-bottom: 1px solid gray;
  display: inline-block;
  font-size: 0.7em;
  display: inline-block;
  width: calc(95% - 30px);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif

  
}

.MuiBreadcrumbs-li >div {
  background-color: transparent;
}

.Navigator span {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: inherit;
}


@media (max-width: 460px) {

  .Navigator {
    padding-top: 35px;
  }

  .Navigator span {
    padding: 0;
    margin-left: 10px;
    font-size: .6em;
    font-weight: bold;
  }

}


.toolbar{
    background: grey;
    display: block;
}


.toolbar button {
    float: right;
    background: #0000005b;
}
* {
}

.App {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header-page h2:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#root {
  height: 100%;
}

.HorizentalMenuDiv {
  cursor: pointer;
  padding-top: 10px;
  width: 210px;
  height: 100%;
  background: #b8b3b3e8;
  box-shadow: 0 6px 6px 2px black;
  transition: width 1s;


}

.Body {
  margin-left: 30px;
  margin-right: 10px;
  overflow-y: auto;
  height: calc(100% - 50px);
  padding-left: 0;
  position: relative;
  font-size: .8em;

}




#userProfilePic {
  border-radius: 50%;
}

.header-page {
  padding-top: 3px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  background: -webkit-linear-gradient(left, #1579fb, #1580fb);
  position: fixed;
  z-index: 1007;
}

.ContentWrapper {
  width: 100%;
  height: calc(100vh - 40px);
  display: grid;
  grid-template-columns: 210px calc(100% - 270px);
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  position: absolute;
  top: 51px;
  transition: all 1s ease-in-out;
}

.wip-panel{
  height: calc(100% - 50px);
  width: calc(100% - 210px);
  position: fixed;
  left: 210px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  background-color: #80808082;
  z-index: 1005;
}



@-webkit-keyframes toggleMenu {
  from {
    grid-template-columns: 250px calc(100% - 270px);
  }
  to {
    grid-template-columns: 90px calc(100% - 110px);
  }
}



@keyframes toggleMenu {
  from {
    grid-template-columns: 250px calc(100% - 270px);
  }
  to {
    grid-template-columns: 90px calc(100% - 110px);
  }
}

.header-page button.menu-toggler i {
  font-size: 1.4em;
}
.notification-section {
  display: flex;
  margin-left: auto;
}

.notification-section i.fa-bell{
  color: white;
}

.notification-section .MuiBadge-root {
  align-self: center;
  padding-right: 8px;
}

.notification-section
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorError {
  margin-top: 5px;
  margin-right: 8px;
}

.notification-section .MuiPaper-root i {
  font-size: 22px;
  padding-right: 5px;
}

.notification-section ul {
  padding-bottom: 0;
}

.notification-section li {
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
  border-top: 1px solid gray;
}

.notification-section li span {
  padding-left: 5px;
  color: red;
}

.LogedUserClass {
  align-self: center;
  margin-left: auto;
  text-align: center;
  vertical-align: middle;
  padding-right: 35px;
}

.LogedUserClass label {
  font-size: 14px;
  font-weight: 600;
}

.LogedUserClass > * {
  margin-left: 5px;
}

.LogedUserClass span {
  background: #808080e0;
  border-radius: 50%;
  border: 2px solid gray;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.LogedUserClass:hover {
  cursor: pointer;
}

.ContentWrapper.reduced {
  -webkit-animation: toggleMenu 1.5s linear;
          animation: toggleMenu 1.5s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  transition: all 1s ease-in-out;
}

.ContentWrapper.reduced .HorizentalMenuDiv {
  width: 90px;
  transition: width 1s;
}

.ContentWrapper.reduced .Body {
  transition: width 1s;
}

.ContentWrapper.reduced .HorizentalMenuDiv span:nth-child(2n) {
  display: none;
}


.edit-panel span.MuiCheckbox-root{
  width: 1rem !important;
  margin-top: .8rem ;
}


.no-msg-content {
  font-size: .8em;
  font-weight: 800;
  color: rgb(51, 183, 7);
  text-align: center;
}

.notification-menu-content {
  min-width: 250px;
  font-size: .8em;
}

.notification-menu-content * {
  padding: 10px;
}

.notification-menu-content .header {
  font-weight: 800;
  color: rgb(203, 13, 48);
  padding: 10px;
  border-bottom: 1px solid black;
  width: 100%;
  display: block;
}

#notification-menu ul {
  padding: 0 0 10px 0;
}

.notification-menu-content  li {
  font-size: .8em;
  color: red;
  font-weight: 700;
}

.notification-menu-content span{
  white-space: break-spaces;
}

@media (max-width: 610px) {
  button.menu-toggler {
    display: none;
  }

  .Body{
    overflow-y: auto;
    max-height: calc(100% - 135px);
  }

  .ContentWrapper {
    display: block;
  }

  .ContentWrapper .HorizentalMenuDiv {
    transition: width 1s;
    position: absolute;
    background: transparent;
    width: 50px;
    height: 275px;
    border: 0;
    box-shadow: none;

  }

  .HorizentalMenuDiv ul {
    background: #b8b3b3e8;
  }



  .horizentalMenu span:nth-child(2n) {
    display: none;
  }
  .horizentalMenu * {
    padding-left: 3px !important;
  }
  .datatable-toolbar span.MuiButton-label > span {
    display: none;
  }
  .datatable-toolbar > div:first-child {
    display: flex;
    align-items: center;
  }
  .datatable-toolbar .MuiButton-root {
    min-width: auto !important;
  }
  .Body .edit-panel div {
    display: block !important;
    padding-top: 1px;
  }

  .login > div {
    width: 85%;
  }
}

.logged-user-recap-menu {
  display: flex;
  justify-self: center;
  height: 100%;
  padding-left: 3px;
  font-size: 0.8rem;
  font-weight: bolder;
  line-height: 10px;
}

.TextAreaError {
  border-color: red;
}


td.MuiTableCell-root {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 460px) {
  .LogedUserClass label {
    display: none;
  }
  .wip-panel{
    left: 0 !important;
    top: 220px;
    width: 100%;
  }
  .Body {
    position: absolute;
    top: 120px;
    width: 100%;
  }
  .horizentalMenu >div {
    grid-template-columns: 100%;
    
  }

}

::-webkit-scrollbar {
  width: 5px;
  background: rgb(180, 180, 180);
  height: 3px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
}

.change-password {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.change-password h3{
  text-transform: uppercase;
  letter-spacing: .1em;
}

.change-password > div {
  display: none;
  text-align: center;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.change-password > div label {
  padding-top: 10px;
}

.change-password input {
  text-align: center;
}

.action-panel {
  display: grid;
  grid-template-columns: repeat(2,calc(50% - 5px));
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  margin-top: 20px;
  text-align: center;
}

body * {
  margin: 0;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

body {
  background: rgb(0 0 0 / 2%);

}

span, label, div {
  font-family:'Poppins',sans-serif !important;

}

td span , td i  {
  font-size: .8em !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  overflow: hidden;
}


button.primary {
  background: green;
  border: 0;
  border-radius: 5px;
  color: white;
}

button.secondary {
  background: red;
  border: 0;
  border-radius: 5px;
  color: white;
}

.action-message.success {
  background: green;
}

.action-message.failed {
  background: red;
}

.action-message {
  height: 50px;
  display: flex;
  flex-direction: row;
}

.action-message * {
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.edit-panel {
  padding: 10px;
  margin: auto;
  border-radius: 4px;
  position: relative;
  margin-top: 50px;
  background: rgba(255, 255, 255, .5);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
   0px 1px 1px 0px rgb(0 0 0 / 24%),
    0px 1px 3px 0px rgb(0 0 0 / 22%);
}

.edit-panel input, .edit-panel div.MuiSelect-root {
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-panel div.MuiFormControl-root {
  margin: 12px 20px 0px 10px;
}

.edit-panel label.MuiInputLabel-formControl {
  -webkit-transform: translate(0) scale(0.75);
          transform: translate(0) scale(0.75);
}

.edit-panel span.edit-panel-header {
  position: absolute;
  top: -1.5em;
  left: 0;
  padding-left: 3px;
  color: #000000a6 ;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-transform: uppercase;
  white-space: nowrap;
}

.edit-panel-content {
  padding: 30px;
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.action-panel {
  width: 100%;
  text-align: center;
}

.action-panel button{
  margin: 10px !important
}

.form-action-button {
  background: #52af4c !important;
  margin: 10px !important;
  color: white !important;
}

.icon-button {
  border: 0;
  background: transparent;
  cursor: pointer;
  padding-right: 5px;
}

.hide {
  height: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  overflow: hidden;
  margin: 0 !important;
}

.DisplayNone {
  display: none !important;
}

.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.Mui-required {
  position: absolute;
  bottom: -1.25rem;
}



.panel-header::after {
  content: "";
  border: 20px 0 0 0 blue solid;
}

.MuiSnackbar-anchorOriginTopRight {
  right: 70px !important;
}

.MuiPaper-root.MuiSnackbarContent-root {
  background: red !important;
  font-weight: 600;
}

.maxWidth130{
  max-width: 130px !important;
}

.maxWidth150{
  max-width: 150px;
} 
.whiteSpaceNoWrap{
  white-space: nowrap;
}
.textOverflowEllipsis{
  text-overflow: ellipsis;
}
