.horizentalMenu {
  padding: 0;
  color: rgba(0, 0, 0, 0.58);

}

.horizentalMenu span, .horizentalMenu i{
  font-size: .8em;
}


.horizentalMenu li{
  padding: 5px
}


.horizentalMenu ul.level1 {
  padding: 0;
  border-top: 1px solid rgba(128, 128, 128, 0.329);
}

.horizentalMenu ul.level2 {
margin-top: 10px;
background-color: rgba(128, 128, 128, 0.129);
padding-left: 0;
margin-left: 20px
;

}
.dashboard{
  cursor: pointer;
  margin-top: 3px;
}
.dashboard:hover{
  background-color: rgba(128, 128, 128, 0.129);
}

.horizentalMenu ul.level2 li {

  border-bottom: 1px solid white;
}

.horizentalMenu ul.level2 li {
 font-size: .6em;
 padding-left: 5px;
}


.horizentalMenu span:nth-child(2n+1) {
  padding: 0 5px 0 0;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
}

.horizentalMenu i {
  font-size: 1.3em;
}

.horizentalMenu > div {
  width: 100%;
  font-size: 1em;
  padding-bottom: 5px;
  font-weight: 800;
  position: relative;
  border-bottom: 1px white solid;
  box-shadow: 1px 1px 1px gray;
}

.horizentalMenu > div > div{
  padding: 5px;
  height: fit-content !important;
}


.horizentalMenu div ul {
  margin-left: 10px;
  margin-top: 20px;
  padding-top: 10px;
}

.horizentalMenu div li{
  padding: 8px ;
  margin-right: 10px;
  font-size: .8em;
}


.horizentalMenu ul.level1 {
  overflow: hidden;
  height: 0;
  transform: scaleY(0);
  transform-origin: 50% 0;
  transition: transform 0.3s linear;
}

.horizentalMenu ul.show-menu {
  height: auto;
  transform: scaleY(1);
  transition: .7s ease;
}

div.arrow-left{
  position: relative;
}
.horizentalMenu div.arrow-left::after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 6px solid transparent;
  position: absolute;
  border-left: 6px solid white;
  border-top: 6px solid transparent;
  right: 10px;
  top: 15px;
}



.horizentalMenu div.arrow-bottom::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  position: absolute;
  border-top: 6px solid white;
  border-right: 6px solid transparent;
  right: 10px;
  top: 15px;
}

ul.horizentalMenu li {
  list-style-type: none;
  padding: 8px;
  padding-left: 20px;
  font-weight: 500;
}

ul.horizentalMenu>li {
  border-bottom: 1px solid white;
  padding: 0 10px 0 15px;
  box-shadow: 0 4px 4px grey;
}

ul.horizentalMenu>li:nth-child(n + 2) {
  padding-top: 10px;
}

.horizentalMenu li.menu:hover , .horizentalMenu li.menu.active {
  background-color: unset !important;
  color: unset;
  opacity: 1 !important
}

.horizentalMenu li.menu{
  position: relative;
}

.horizentalMenu ul>li:hover {
  cursor: pointer;
  background-color: gray;
  opacity: 0.5;
  color: white;
}

.horizentalMenu .active {
  background-color: gray;
  color: white
}


.HorizentalMenuDiv  {
  background: #b8b3b3e8 !important;
}


.horizentalMenu >div {
  padding-top: 5px;
}


@media (max-width: 460px) {

  .HorizentalMenuDiv  {
    background: none !important;
  }
  .horizentalMenu >div {
    width: 100% !important;
    display: grid !important;
    grid-template-columns: 50% 50%;
  }


  .horizentalMenu div ul {
    margin-top: 15px !important;
  }

  .horizentalMenu .level2 >li {
    margin-left: 10px;
    display: flex;
    justify-content: flex-start;
  }
  
  .horizentalMenu>li {
    padding: 0;
  }

  .ContentWrapper {
    display: grid !important;
    grid-template-columns: 100% !important;
    
  }

  .ContentWrapper .HorizentalMenuDiv {
    width: 100% !important;
    height: auto !important;
    position: fixed !important;
    z-index: 1;
  }

  .horizentalMenu {
    margin-top: 0;
    width: 100% !important;
  }


  .horizentalMenu > div{
    display: inline-flex;
    width: calc(50% - 5px);
    flex-direction: column;
  }
  .horizentalMenu > div > div{
    background: #b8b3b3e8;
  }



  .horizentalMenu > div span{
    display: inline !important;
    font-size: .8em;
  }

  .horizentalMenu > div .level1 span{
    font-size: .7em;
  }

  .Body {
    margin : 40px 0 0 0px !important;
    height: calc(100% - 80px);
  }

  .Body  span , .Body  label , .Body  td{
    font-size: .8em !important;
  }

}
