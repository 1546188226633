.Navigator {
  padding: 10px;
  font-weight: bold;
  margin: 10px 5% 0 10px;
  border-bottom: 1px solid gray;
  display: inline-block;
  font-size: 0.7em;
  display: inline-block;
  width: calc(95% - 30px);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif

  
}

.MuiBreadcrumbs-li >div {
  background-color: transparent;
}

.Navigator span {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: inherit;
}


@media (max-width: 460px) {

  .Navigator {
    padding-top: 35px;
  }

  .Navigator span {
    padding: 0;
    margin-left: 10px;
    font-size: .6em;
    font-weight: bold;
  }

}

