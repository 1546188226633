.publicite-workspace {
  width: calc(100% - 40px);
  padding: 20px;
}

.publicite-workspace > div > span {
  font-size: 0.8em;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.publicite-workspace .file-upload {
  display: grid;
  grid-template-columns: 400px 200px 200px;
  align-items: center;
  column-gap: 20px;
  justify-content: center;
}

.publicite-workspace .upload-histroy {
  background-color: rgba(128, 128, 128, 0.294);
  padding: 20px;
  box-shadow: 1px 1px 3px gray;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 200px 200px 200px 200px;
  justify-content: center;
  row-gap: 5px;
}

.publicite-workspace .upload-histroy span:nth-child(2n + 1) {
  text-decoration: underline dashed;
  text-underline-offset: 8px;
}

.publicite-workspace .upload-histroy span:nth-child(2n) {
  text-transform: unset;
  color: green;
}

.publicite-workspace .workspace-compiler {
  margin: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
}
.publicite-workspace .workspace-compiler > div {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  column-gap: 20px;
}

.publicite-workspace .workspace-compiler .wrap {
  width: 100%;
  height: calc(100vh - 290px);
  padding: 0;
  overflow: hidden;
}

.publicite-workspace .workspace-compiler iframe {
  width: 1280px;
  height: 786px;
  transform: scale(0.35);
  overflow: auto;
}

.deploy-pub-alert {
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-self: center;
  border: unset;
}
.deploy-pub-alert .pub-content {
  background: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: unset;

}

.deploy-pub-alert .message {
  display: flex;
  column-gap: 10px;
  justify-items: center;
  align-items: center;
}

.deploy-pub-alert .message i {
  color: orange;
}
.deploy-pub-alert .message span {
  font-weight: 700;
}

.deploy-pub-alert .pub-toolbar button {
  margin: 20px 0 5px 10px;
}

.file-upload .MuiSelect-root{
  padding-left: 10px;
}

@media (max-width: 1028px) {
  .publicite-workspace .upload-histroy {
    grid-template-columns: 200px 200px;
    row-gap: 30px;
    justify-content: center;
    justify-items: center;
  }

  .publicite-workspace .file-upload {
    grid-template-columns: 300px;
    justify-content: center;
    justify-items: center;
    row-gap: 20px;
  }
  .publicite-workspace .file-upload > div {
    display: flex;
    flex-direction: column;
    justify-items: center;
    row-gap: 10px;
  }
}

@media (max-width: 760px) {
  .publicite-workspace .upload-histroy {
    grid-template-columns: calc(100% - 20px);
    row-gap: 30px;
    justify-content: center;
    justify-items: center;
  }

  .file-upload .MuiSelect-root{
    width: 100px;
    padding-left: 10px;
  }
}
