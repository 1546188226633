.dashboard-chart {
  display: flex;
  column-gap: 20px;
  width: 90%;
  margin: auto;
}

.dashboard-chart > div span{
  font-weight: 800;
  letter-spacing: .2em;
  font-size: .7em;
  text-align: center;
  border-bottom: 1px solid rgb(76, 75, 75);
  padding-bottom: 5px;
}

.dashboard-chart > div{
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  width: 200px;
  border: 1px solid rgb(239, 239, 239);
  padding: 10px;
  margin: 10px;
  border-radius: 30px;
  box-shadow: 1px 1px 1px gray;

}
.card {
  padding: 10px;
  margin: 15px;
}
.cards{
  display: flex;
  flex-direction: row
}



@media only screen and (max-width: 900px) {
  .cards{
    flex-direction: column;
  }
}