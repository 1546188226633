.home-component {
    width: calc(90% - 20px);
    margin: auto;
    padding: 20px;
}

.home-component h2 {
    margin-top: 10px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, .7);
    text-align: center;
    font-family: monospace;
    font-size: 1.2em;
    font-weight: bolder;
    letter-spacing: .3em;
}

.home-component h3 {
    margin-top: 45px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.3em;
}

.home-link-section {
    margin-top: 30px;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 250px);
    column-gap: 20px;
    font-weight: 600;
    letter-spacing: .2em;
    justify-content: center;
}

.home-link-section img {
    width: 3em;
    font-weight: 800;
}

.home-link-section div {
    padding: 10px;
    box-shadow: 1px 1px 5px gray;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    row-gap: 20px;
    padding-bottom: 20px;
    cursor: pointer;
    transition: all ease 1s;
    background: rgba(255, 255, 255, .75) ;
}

.home-link-section div:hover {
background: rgba(0, 0, 0, .15);
}

@media (max-width: 850px) {
    .home-link-section {
        grid-template-columns: 100%;
        row-gap: 20px
    }
}