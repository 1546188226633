.users .MuiListItem-root.MuiListItem-gutters {
  padding-bottom: 0;
  padding-top: 0;
}

.users .upload-component {
  display: flex;
}

.users .upload-panel button {
  padding: 10px;
  border-radius: 50%;
  height: 40px;
}

.users .pic-displayer-panel {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.users .pic-displayer-panel img {
  border-radius: 50%;
}

.users .pic-displayer-panel input {
  width: 300px !important;
  font-weight: 800;
  font-size: 0.6em;
  text-align: center;
}

.users .fa-image {
  color: blue;
  font-size: 0.7em;
}

.users .fa-upload {
  color: green;
  font-size: 0.7em;
}

#pic-displayer {
  font-size: 0.7em !important;
  white-space: nowrap;
}

.users .contained,
.users .roles-container {
  max-width: 500px;
  margin: auto !important;
  display: flex;
  justify-items: center;
  align-items: center;
  column-gap: 10px;
  min-height: 150px;
}

.users .contained > label {
  margin: 13px 0 0 13px;
  font-weight: 800;
  font-size: 0.9em;
  color: black;
}

.users .roles-container > ul {
  margin-top: 10px;
  font-size: 0.7em;
  font-weight: 700;
}

.pos-check-list {
  padding: 15px 0 0 10px;
}

.error-modal {
  background: white ; 
  width: 400px ;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)

}

.error-modal > div {
  padding: 30px;
}

.error-modal > div i{
  color: orange
}

.error-modal h3{
  background : red;
  color: white;
  padding: 10px;
  font-weight: 700;
}

.error-modal span {
  font-weight: 800;
  font-size: .8em;
  margin-left: 5px;
}

.pos-check-list-content {
  overflow: auto;
  max-height: 62px;
}

.pos-check-list-content ul {
  max-height: 200px;
}



.pos-check-list-header > div {
  display: flex;
  align-items: center;
  column-gap: 4px;
  padding-bottom: 10px;
  justify-content: center;
}

.pos-check-list-header span {
  font-weight: 800;
  font-size: 0.7em;
}

.pos-check-list-content ul {
  list-style: none;
  padding-left: 10px;
}

.pos-check-list ul li {
  vertical-align: middle;
  padding-left: 4px;
}

.pos-check-list ul li span.MuiCheckbox-root {
  padding: 2px 3px 2px 3px;
  margin: 0;
}

.pos-check-listItem {
  padding-top: 10px;
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.pos-check-listItem span {
  font-size: 0.7em;
  font-weight: 700;
}

#user-rel {
  width: 600px;
  margin: auto;
}

#user-rel > .pos-check-list {
  width: 500px;
}

#user-rel > .pos-check-list  input{
  width: 250px !important;
}

.users div[role="tabpanel"] {
  padding: 0;
}

@media (max-width: 990px) {
  #user-rel {
    grid-template-columns: 50% 50% !important;
  }
}

@media (max-width: 755px) {
  .Body .edit-panel .upload-component {
    display: flex !important;
    flex-direction: column;
    justify-items: center;
  }
  .pic-displayer-panel {
    margin: auto;
    text-align: center;
  }

  #user-rel {
    width: 100%
  }

  .users .pic-displayer-panel input {
    width: 100% !important;
    text-align: center;
  }

  #user-rel > .pos-check-list {
    width: 100%;
}

  #user-rel {
    grid-template-columns: 100% !important;
  }

  .ContentWrapper {
    height: calc(100% - 70px) !important;
  }
}
