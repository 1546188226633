.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #80808078;
}

.login > div {
  border: 1px solid gray;
  width: 500px;
}

.login div.header {
  display: inline-block;
  vertical-align: middle;
  background: -webkit-linear-gradient(left, #00eaff, #676767) !important;
  color: white;
  font-weight: bolder;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  text-align: center;
  width: 100%;
}
.content {
  width: 100%;
  background: #ffffffb8;
  padding: 15px;
}


.content > div {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;

}

.content > div input {
  background: white;
  padding-left: 20px;
  margin:0; 
  width: 100%;
}

.content >div div:nth-child(2){
  flex-basis: 100%
}


.content >div>span {

  width: 50px;
  display: flex;
  background: gray;
  justify-content: center;
}


.content i {
  display: flex;
  flex-direction: row;
  justify-content: center ;
  margin:auto;
  color: white;
  font-size: 1.8em;
  flex-basis: 50px;
}
.content input {
  border: 0;
  margin-left : 10px;
  padding-left: 10px;
  width:230px
}

.content button {
  padding: 10px;
  width: 80%;
  margin: 15px;
  font-weight: bolder;
}

span.fail {
  background: red;
  color: white;
  font-weight: bolder;
  text-align: center;
  width: 100%;
  display: inline-block;
}

span.success {
  background: rgb(0, 255, 13);
  color: white;
  font-weight: bolder;
  text-align: center;
  width: 100%;
  display: inline-block;
}

.authentication-panel {
  display: flex;
}

.authentication-panel > div:first-child {
  border: gray 1px solid;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  background: -webkit-linear-gradient(bottom, #1579fb, #1580fb) !important;
}

.authentication-panel > div:first-child > img {
  margin: auto;
}
