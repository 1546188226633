@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Ubuntu:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

body * {
  margin: 0;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

body {
  background: rgb(0 0 0 / 2%);

}

span, label, div {
  font-family:'Poppins',sans-serif !important;

}

td span , td i  {
  font-size: .8em !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  overflow: hidden;
}


button.primary {
  background: green;
  border: 0;
  border-radius: 5px;
  color: white;
}

button.secondary {
  background: red;
  border: 0;
  border-radius: 5px;
  color: white;
}

.action-message.success {
  background: green;
}

.action-message.failed {
  background: red;
}

.action-message {
  height: 50px;
  display: flex;
  flex-direction: row;
}

.action-message * {
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.edit-panel {
  padding: 10px;
  margin: auto;
  border-radius: 4px;
  position: relative;
  margin-top: 50px;
  background: rgba(255, 255, 255, .5);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
   0px 1px 1px 0px rgb(0 0 0 / 24%),
    0px 1px 3px 0px rgb(0 0 0 / 22%);
}

.edit-panel input, .edit-panel div.MuiSelect-root {
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-panel div.MuiFormControl-root {
  margin: 12px 20px 0px 10px;
}

.edit-panel label.MuiInputLabel-formControl {
  transform: translate(0) scale(0.75);
}

.edit-panel span.edit-panel-header {
  position: absolute;
  top: -1.5em;
  left: 0;
  padding-left: 3px;
  color: #000000a6 ;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-transform: uppercase;
  white-space: nowrap;
}

.edit-panel-content {
  padding: 30px;
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 5px;
}

.action-panel {
  width: 100%;
  text-align: center;
}

.action-panel button{
  margin: 10px !important
}

.form-action-button {
  background: #52af4c !important;
  margin: 10px !important;
  color: white !important;
}

.icon-button {
  border: 0;
  background: transparent;
  cursor: pointer;
  padding-right: 5px;
}

.hide {
  height: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  overflow: hidden;
  margin: 0 !important;
}

.DisplayNone {
  display: none !important;
}

.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.Mui-required {
  position: absolute;
  bottom: -1.25rem;
}



.panel-header::after {
  content: "";
  border: 20px 0 0 0 blue solid;
}

.MuiSnackbar-anchorOriginTopRight {
  right: 70px !important;
}

.MuiPaper-root.MuiSnackbarContent-root {
  background: red !important;
  font-weight: 600;
}

.maxWidth130{
  max-width: 130px !important;
}

.maxWidth150{
  max-width: 150px;
} 
.whiteSpaceNoWrap{
  white-space: nowrap;
}
.textOverflowEllipsis{
  text-overflow: ellipsis;
}