.change-password {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.change-password h3{
  text-transform: uppercase;
  letter-spacing: .1em;
}

.change-password > div {
  display: none;
  text-align: center;
  row-gap: 20px;
}

.change-password > div label {
  padding-top: 10px;
}

.change-password input {
  text-align: center;
}

.action-panel {
  display: grid;
  grid-template-columns: repeat(2,calc(50% - 5px));
  column-gap: 10px;
  margin-top: 20px;
  text-align: center;
}
