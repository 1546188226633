.upload-content {
  margin-top: 25px;
  padding: 15px;
  text-align: center;
}

.upload-content div {
}

.log-panel-header {
  display: flex;
}

.log-panel-header span {
  font-weight: bolder;
  margin-top: -1rem;
  padding: 4px;
}

.log-panel-header i {
  font-weight: bolder;
  margin-top: -0.7rem;
  padding: 4px;
  cursor: pointer;
}

.log-panel-header i:hover {
  opacity: 0.5;
}

.log-panel-header::before {
  content: "";
  width: 20%;
  height: 1px;
  background: gray
}

.panel-header {
  background: -webkit-linear-gradient(left, #1579fb, #1580fb);
  color: white;
  padding: 10px;
  font-weight: 600;
}

.log-panel-header::after {
  content: "";
  width: 80%;
  height: 1px;
  background: gray;
}

.log-panel-content {
  font-size: 12px;
}

.log-panel-content span {
  font-weight: bolder;
  padding: 10px;
  text-transform: uppercase;
  flex-basis: 15%;
}

.log-panel-content div {
  margin-top: 10px;
  padding-left: 10px;
  display: flex;
  margin-bottom: 15px;
}
.log-panel-content div > *:nth-child(2) {
  flex-basis: 70%;
}

.log-panel-content .response-panel textarea {
  flex-basis: 100%;
  overflow-y: auto;
}



.select-file-panel  span {
  margin-top: 1rem;
  text-transform:uppercase ; 
  font-size: small;
  font-weight: 600;
  color: green
}

.select-file-panel:hover{
  cursor: pointer;
  opacity: 1;
  background: rgb(250,235,215, .03);

}

.select-file-panel:active{
  opacity: 0.5;
  background: rgb(250,235,215, .03);
}

.upload-step-panel,.select-file-panel {
  display: flex;
  height: 150px;
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
  margin-bottom: 10px;

  background: rgb(250,235,215, .33);
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px dashed green;
  box-shadow: 3px 2px 9px 0 grey;
}

.select-file-panel span {
  padding: 5px
}
