.search-panel-content {
  border: 1px solid gray;
  background: white;
}

.search-panel-content h3 {
  margin: 0;
  padding: 10px;
  background: gray;
  color: white;
}

.search-panel-header .close-icons:hover{
  cursor: pointer;
  background: rgb(138,138,128);
}

.search-panel-content span.close-icon {
  border: 1px solid gray;
  padding: 3px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -1em;
  z-index: 1008;
  background: white;
}

.search-panel-content span.close-icon i {
  position: fixed;
  background: white;
}

.search-panel-content i.close-icon:hover {
  cursor: pointer;
}

.search-panel-content > div div {
  display: flex;
  justify-content: center;
}

.search-panel-content div > * {
  padding: 5px;
}

.search-panel-content div > label {
  align-self: center;
  font-weight: 600;
  text-transform: capitalize;
}

.search-panel-content div.action-panel {
  padding-top: 10px;
  padding-bottom: 5px;
  width: 80%;
  margin: auto;
  display: block;
  margin-top: 15px;
  text-align: center;
}

.search-panel-content div.action-panel button:first-child {
  background: green;
  color: white !important;
  margin: 5px;
}

.search-panel-content div.action-panel button:last-child {
  background: red;
  color: white !important;
  margin: 5px;
}

.search-panel-content div.action-panel button > span {
  width: 24px;
  height: 24px;
}

.search-panel-header {
  display: flex;
  flex-direction: row;
}

.search-panel-header h3 {
  flex-basis: 100%;
  background: gray;
  padding: 5px;
  color: white;
  padding-left: 15px;
}

.search-panel-header > div {
  display: inline-block;
  background: gray;
  width: 60px;
}

.search-panel-header > div > div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
}

.search-panel-header > div > div:first-child {
  background: green;
}

.search-panel-header > div > div:nth-child(2) {
  background: orange;
}

.search-panel-header > div > div:last-child {
  background: red;
}


.datatable-toolbar div.search-panel {
  position: fixed;
  min-width: 300px;
  width: 30vw;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  transition: all 0.3s linear;
  box-shadow: 1px 2px 8px;
  top: 180px

}


@media (max-width: 600px) {
  .datatable-toolbar div.search-panel {
    height: 100vh;
    width: 100vw;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background: #808080a8;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1008;
  }

  .datatable-toolbar div.search-panel > div {
    height: auto;
  }

  .search-panel-header > div {
    width: 60px;
  }
}
