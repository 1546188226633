th {
    font-size: .8em !important;
    font-weight: 700 !important;
    white-space: nowrap;
}
td {
    font-size: .8em !important;
}

.command-table div.MuiTableContainer-root{
    overflow: hidden;
}


@media (max-width: 760px) {
    .command-table div.MuiTableContainer-root{
        overflow: auto;
    }
    
}