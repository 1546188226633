.filter-section{
  margin-top: 25px;
  line-height: 0;

}
.filter-section span:first-child {
  text-transform: uppercase;
}
.filter-section div> div:first-child{
  margin-left: 0 !important;
}

.filter-section > div{
  max-width: 90%;
  display: inline-flex;
  background: transparent !important;
  flex-direction: row;
  margin-left: 0 ! important;
  margin-top: 5px ! important;
  padding: 6px !important;
  padding-left: 0 !important;
  overflow-x: auto;

}

.filter-section>span {
  font-weight: 600;
  padding-right: 5px;
}

.filter-section >div>div * {
  display: inline;
}

.filter-section div {
  background: #8080806b;
  color: black;
  border-radius: 5px;
  padding: 4px;
  font-weight: 400;
  margin-left: 5px;
}

.filter-section div i:hover {
  cursor: pointer;
  opacity: 1.5;
}

.filter-section div i {
  margin-left: 5px;
  opacity: 0.5;
}

.filter-section div>span:first-child {
  font-size: 12px;
  font-weight: bolder;
  margin-left: 3px;
}

.filter-section div>span:nth-child(2n) {
  color: white;
  font-size: 14px;
  font-weight: bolder;
  margin-left: 3px;
}

.datatable-toolbar {
  margin-top: 25px;
  border-bottom: 1px solid black;
  display: flex;
  background: #ffffff78;
}



.red-color {
  color: red !important
}
